<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-casino"/><strong> Characters </strong>
                            <div class="card-header-actions">
                                <small class="text-muted">Total: {{ chars.length }}</small>
                            </div>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :items="chars"
                                :fields="charFields"
                                :hover="hover"
                                column-filter
                                items-per-page-select
                                :items-per-page="10"
                                sorter
                                pagination >
                            <template #ownerbadge="{item}">
                                <td>
                                    <CBadge :color="$config.userClr(item.ownerFlags)">{{ $config.userTxt(item.ownerFlags) }}</CBadge>
                                </td>
                            </template>
                            <template #timePlayed="{item}">
                                <td class="py-2">{{ item.timePlayed | formatTimeSpan }}</td>
                            </template>
                            <template #updated="{item}">
                                <td class="py-2">
                                    {{ item.updated | formatDate }}
                                </td>
                            </template>
                            <template #created="{item}">
                                <td class="py-2">
                                    {{ item.created | formatDate }}
                                </td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageChar(item.id)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                            <template #manageowner="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageOwner(item.userId)">
                                        Manage Owner
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Characters",
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                hover: true,
                chars: [],
                charFields: [
                    { key: "name", label: "Name" },
                    { key: "ownerName", label: "Owner" },
                    { key: "ownerbadge", label: "", filter: false, sorter: false },
                    { key: 'worldId', label: 'World Id', filter: false, sorter: false },
                    { key: 'timePlayed', label: 'Played', filter: false, sorter: false },
                    { key: 'updated', label: 'Updated', filter: false, sorter: false },
                    { key: 'created', label: 'Created', filter: false, sorter: false },
                    { key: 'manage', label: '', filter: false, sorter: false },
                    { key: 'manageowner', label: '', filter: false, sorter: false },
                ],
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getCharacters() {
                this.isLoading = true;

                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/chars', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.chars = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.isLoading = false;
                });
            },
            manageChar(id) {
                this.$router.push({ name: 'Character', params: { id: id } })
            },
            manageOwner(id) {
                this.$router.push({ name: 'Player', params: { id: id } })
            }
        },
        created() {
            this.getCharacters();
        }
    }
</script>

<style scoped>

</style>
